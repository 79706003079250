export const BOOKING_MODE = {
  ALL: "all",
  PLANNED: "planned",
  UN_PLANNED: "unplanned",
};

export const BOOKING_TYPE = {
  NORMAL: "normal",
  EMERGENCY: "emergency",
};

export const SWC = {
  CLEAN: "Clean",
};

export const REQUEST_STATUS = {
  SHEDULED: "scheduled",
  BOOKED: "booked",
  REQUESTED: "Requested",
};

export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const GLOBAL_CONSTANTS = {
  SURGEON: "surgeon",
  MINS: "mins",
  STANDARD_PROCEDURE_TIME: "Standard Procedure time",
  STANDARD_CLEANING_TIME: "Standard Cleaning Time",
  CLEANING_TIME: "Cleaning time",
  EQUIPMENTS: "Equipments",
  ITEM: "Item",
  VENDOR: "Vendor",
  QUANTITY: "Quantity",
  MR_NO: "MR No",
  BED_NO: "Bed No",
  PROCEDURE_TIME: "Procedure time",
  NOTES: "Notes",
  NAME: "Name",
  START_TIME: "Start Time",
  END_TIME: "End Time",
  SURGERY_DURATION: "Surgery Duration",
  CLEANING_DURATION: "Cleaning Duration",
  YEARS: "year(s)",
  MONTHS: "month(s)",
  DAYS: "day(s)",
  NEXT: "Next",
  PREVIOUS: "Previous",
  DONE: "Done",
  SWC: "SWC",
  UHID: "UHID",
  PAC: "pac",
  USER: "user",
  SURGERY: "Surgery",
  POST_OT: "post-ot",
  PRE_OT: "pre-ot",
};

export const GLOBAL_MESSAGES = {
  ENTER_AN_EMAIL: "Enter an email",
  ENTER_A_VALID_EMAIL: "Enter a valid email",
  ENTER_A_PASSWORD: "Enter a password",
  GOOD_MORNING: "Good Morning",
  GOOD_AFTERNOON: "Good Afternoon",
  GOOD_EVENING: "Good Evening",
  ENTER_SURGERY_DETAILS: "Enter surgery details",
  SELECT: "-Select-",
  REMOVE: "Remove",
  FILL_REQUIRED_FIELDS: "Please fill all required fields",
  ENTER_PATIENT_INFO: "Enter Patient Info",
  SKIP_PHARMACY_SUPPLIES: "Skip pharmacy supplies?",
  SKIP_PHARMACY_MESSAGE: "Do you want to skip selecting pharmacy supplies?",
  SELECT_PHARMACY_SUPPLIES: "Select Pharmacy Supplies",
  SELECT_OT_TIME_SLOT: "Select OT & Time Slot",
  SUCCESSFULLY_ADDED: "Successfully Added",
  SUCCESSFULLY_UPDATED: "Successfully Updated",
  TIME_SLOT_UPDATED: "Time Slot Updated",
  SLOT_NOT_AVAILABLE: "Slot not available, please choose another",
  SLOT_AVAILABLE: "Slot Available",
  OT_REQUEST_SUCCESS: "OT Requested Successfully",
  REQUEST_ANOTHER_OT: "Request Another OT",
  BACK_TO_HOME: "Back to Home Page",
  NORMAL: "Normal",
  EMERGENCY: "Emergency",
  SELECT_TIME_ERROR: "Select start time between",
  SELECT_SURGERY_FIRST: "Please Select Surgery first",
  NO_SELECTED_EQUIPMENTS: "Selected equipments will be displayed here",
  SELECT_OT_AND_TIME_SLOT: "Please Select OT & Time Slot",
  SELECT_TIME_SLOT: "Please Select Time Slot",
  EQUIPMENT_ADDED: "Equipment Added",
  AVAILABLE_OT: "Available OT's",
  SELECT_OT: "Please Select OT",
  SLOT_UPDATED_SUCCESS: "Slots updated successfully",
  PLEASE_CHOOSE_WITHOUT_CLEANING_TIME:
    "Please choose start and end time excluding cleaning time",
  ENTER_VALID_NAME: "Enter valid name",
  LISTED: "Listed",
  NON_LISTED: "Non-Listed",
  ENTER_PATIENT_NAME: "Enter patient's name",
  HOW_OLD_IS_YOUR_AGE: "How old is your patient?",
  UHID: "UHID",
  MR_NUMBER: "MR number",
  PLEASE_CHOOSE_WITHOUT_CLEANING_DURATION:
    "Please choose start and end time excluding cleaning duration",
};

export const PROGRESS_STATE = {
  PENDING: "pending",
  COMPLETED: "completed",
};

export const WORDINGS = {
  OT_REQUEST: "OT Request",
  SELECT_DATE: "Select Date",
  SURGERY_DATE: "Surgery Date",
  DONE: "Done",
  SELECT_EQUIPMENTS: "Select Equipments",
  SELECTED_SUPPLIES: "Selected Supplies",
  WHAT_TO_DO_TODAY: "What would you like to do today?",
  LOGOUT_NOW: "Logout Now?",
  UNPLANNED_ALERT: "You are requesting an unplanned booking",
  VIEW_SELECTED_SUPPLIES: "View Selected Supplies",
  VIEW_REQUEST_SUMMARY: "View Request Summary",
  PLANNED: "Planned",
  UN_PLANNED: "Unplanned",
  PHARMACY_SUPPLIES: "Pharmacy Supplies",
  SURGERY_DETAILS: "Surgery Details",
  PATIENT_INFO: "Patient Info",
  TIME_SLOT: "Time Slot",
  NO_EQUIPMENTS_FOUND: "No Equipments Found",
  NO_PHARMACY_SUPPLIES_FOUND: "No Pharmacy Supplies Found",
  NO_STANDARD_PHARMACY_LIST_FOUND: "No Standard Pharmacy List Found",
  UPDATE_SURGERY_TIME: "Update surgery time",
  PREFERRED: "Preferred OT’s",
  NON_PREFERRED: "Non-preferred OT’s",
  EMERGENCY: "Emergency",
  MY_REQUESTS: "My Requests",
  MY_SCHEDULES: "My Schedules",
  REQUEST_DETAILS: "Request Details",
  CONFIRMATIONS: "Confirmations",
  CREW: "Crew",
  BOOKING_MODE: "Booking Mode",
  OTHER_DETAILS: "Other Details",
  EQUIPMENTS: "Equipments",
  STANDARD_PHARMACY_LIST: "Standard Pharmacy List",
  STATUS: "Status",
  UPDATED_BY: "Updated By",
  UPDATED_ON: "Updated On",
  CONFIRMATION: "Confirmation",
  NO_REQUESTS_FOUND: "No Requests Found",
  NO_SCHEDULES_FOUND: "No Schedules Found",
  REQUEST_OT: "Request OT",
  SCHEDULE_DETAILS: "Schedule Details",
  NO_CREW_DETAILS: "No Crew Details",
  NO_CONFIRMATIONS: "No Confirmations",
  LOGIN: "Login",
  QUICK_REQUEST: "Quick Request",
  SELECT_SURGERY_DATE: "Select surgery date ",
  SURGERY_TIMING: "Surgery Timing",
  UPDATE_TIMINGS: "Update Timing",
  REQUEST_OVERVIEW: "Request Overview",
  INCLUDES_CLEANING_TIME: "includes cleaning time",
  BOOKING_OVERVIEW: "Booking Overview",
  HOW_WOULD_YOU_CLASSIFY_SURGERY: "How would you classify this surgery?",
  ELECTIVE: "Elective",
  URGENT: "Urgent",
  INCLUDES_CLEANING_DURATION: "includes cleaning duration",
  PERSONAL_DETAILS: "Personal details",
  INFECTION_STATUS: "Infection status",
  NOTES: "Notes",
  ANAESTHESIA_DETAILS: "Anaesthesia Details",
  BLOOD_BANK_ASSISTANCE: "Blood Bank Assistance",
  ADDITIONAL_ITEMS: "Additional Items",
  ADDITIONAL_INFO: "Additional Info",
  SELECT_TYPE_OF_ANAESTHESIA: "Select type of anaesthesia",
  IS_PAC_REQUIRED: "Is PAC required?",
  YES: "Yes",
  NO: "No",
  PLEASE_SELECT_ALL_REQUIRED_FIELDS: "Please select all required fields",
  IS_BLOOD_BANK_ASSISTANCE:
    "Is blood bank assistance or readiness required for this surgery?",
  ARE_ANY_ADDITIONAL_ITEMS: "Are any additional items needed for this surgery?",
  SELECT_REQUIRED_ADDITIONAL_ITEM: "Select required additional items",
  NO_ITEMS_ADDED: "No Additional Items Added",
  PLEASE_ADD_ADDITIONAL_ITEMS: "Please add additional items",
  NOT_OT_AVAILABLE: "No Available OT's",
  AVAILABLE_OT: "Available OT’s",
  REQUEST_SUMMARY: "Request Summary",
  LISTED_HOURS: "Listed Hrs",
  NON_LISTED_HOURS: "Non-Listed Hrs",
  PAC_REQUIRED: "PAC required",
  BLOOD_BANK_REQUIRED: "Blood bank readiness required",
  ANAESTHESIA: "Anaesthesia",
  PRE_EXISTING_INFECTIONS: "Pre-existing Infections",
  SAVE_CHANGES: "Save changes",
  EQUIPMENT_UPDATED: "Equipment Updated Successfully",
  MR_NUMBER: "MR Number",
  UHID: "UHID",
  BLOOD_BANK_READINESS: "Blood bank readiness",
  REQUIRED: "Required",
  MATERIAL_STORE_LIST: "Material store list",
  CSSD_KIT_LIST: "CSSD kit list",
  NO_CSSD_KIT_FOUND: "No CSSD Kit found",
  LOG_PRE_OT_ENTRY: "Log Pre-OT Entry",
  PRE_OT_ENTRY: "Pre-OT Entry",
  statusUpdateConfirmationMessegePreOT:
    "Would you like to mark Pre-OT entry for",
  AREA: "Area",
  LOG_POST_OT_STATUS: "Log Post-OT Status",
  POST_OT_STATUS: "Post-OT Status",
  PLEASE_SELECT_PRE_OT_AREA: "Please select your pre-OT Area to view list",
  PLEASE_SELECT_POST_OT_AREA: "Please select your post-OT Area to view list",
  NO_REQUESTS_FOUND_STATUS_UPDATE: "- No Requests Found -",
  PRE_OT_AREA: "Pre-OT",
  POST_OT_AREA: "Post-OT",
  wouldYouLikeToMark: "Would you like to mark that",
  hasBeenShiftedFrom: "has been shifted from",
  NO_SURGERIES_SCHEDULED_FOR_THIS: "No surgeries scheduled for this",
  PRE_OT: "pre-OT",
  POST_OT: "post-OT",
  ALL: "All",
  UPCOMING: "Upcoming",
  OVERDUE: "Overdue",
};

export const BUTTON_TYPES = {
  REMOVE: "Remove",
  CANCEL: "Cancel",
  ADD: "Add",
  SAVE: "Save",
  PROCEED: "Proceed",
  DONE: "Done",
  LOGOUT: "Logout",
  SKIP: "Skip Step",
  RESET: "Reset",
  SELECT: "Select",
  REQUEST: "Request",
  UPDATE: "Update",
  VALIDATE: "Validate",
  CHECK_AVAILABILITY: "Check Availability",
  FIND_AVAILABLE_OT: "Find available OT’s",
  EDIT_TIME: "Edit time",
  SET_TIME: "Set time",
  ENTER_PATIENT_INFO: "Enter patient info",
  NEXT_INFECTION_STATUS: "Next : Infection status",
};

export const DATE_TIME_FORMAT = {
  DD_MMM_YYYY: "DD MMM YYYY",
  Do_MMMM_YYYY: "Do MMMM YYYY",
  hh_mm_A: "hh:mm A",
  DAY: "day",
  X: "x",
  MILLISECONDS: "milliseconds",
  MINUTES: "minutes",
  Do_MMM_YYYY: "Do MMM YYYY",
};

export const TAB_TYPES = {
  REQUEST_DETAILS: "requestDetails",
  CONFIRMATIONS: "confirmations",
  CREW: "crew",
  SCHEDULE_DETAILS: "scheduleDetails",
};

export const MODE = {
  unplanned: "Unplanned",
  planned: "Planned",
};

export const API_WORDINGS = {
  confirmed: "Confirmed",
  pending: "Pending",
  Anaesthetist_Readiness: "Anaesthetist Readiness",
  Blood_Bank: "Blood Bank",
  CSSD: "CSSD",
  Housekeeping_Readiness: "Housekeeping Readiness",
  Nurse_Readiness: "Nurse Readiness",
  Pharmacy: "Pharmacy",
  Technicians_Readiness: "Technicians Readiness",
};

export const PAGE_DATA = {
  DATE: "date",
  TAB: "tab",
  REDIRECT_PAGE: "redirectPage",
  OPERATIVE_AREA_FILTER_POST_OT: "operativeAreaFilterPostOt",
  OPERATIVE_AREA_FILTER_PRE_OT: "operativeAreaFilterPreOt",
};

export const PAGES = {
  QUICK_BOOKING: "quickBookingRequest",
  REQUEST_OT: "newBookingRequest",
};

export const PROGRESS_STEP_LABELS = {
  OT_SELECTION: "OT selection",
  PATIENT_INFO: "Patient info",
  PHARMACY_SUPPLIES: "Pharmacy supplies",
  SELECT_OT: "Select OT",
  ADDITIONAL_INFO: "Additional info",
};

export const PATIENT_NAME_REGEX = /^[A-Za-z\s]+$/;

export const BOOKING_OVERVIEW_TABS = {
  NON_LISTED: "Non-Listed",
  LISTED: "Listed",
  EMERGENCY: "emergency",
  NORMAL: "normal",
};

export const RADIO_CONSTANTS = {
  YES: "yes",
  NO: "no",
};

export const SURGERY_CLASSIFICATION = {
  ELECTIVE: "elective",
  EMERGENCY: "emergency",
  URGENT: "urgent",
};

export const SURGERY_TYPES = {
  MINOR: "minor",
  MAJOR: "major",
  MAJOR_SURGERY: "Major surgery",
  MINOR_SURGERY: "Minor surgery",
};

export const PATIENT_FIELDS = {
  MRN: "MRN",
  AGE: "Age",
  ANAESTHESIA: "Type Of Anesthesia",
  SSI: "SWC",
  PEI: "Pre-Existing Infection",
  UHID: "UHID",
};

export const VALIDATION_MESSEGES = {
  PATIENT_NAME_IS_REQUIRED: "Patient Name is required",
  ENTER_VALID_NAME: "Enter valid name",
  AGE_IS_REQUIRED: "Age is required",
  ENTER_VALID_AGE: "Enter valid age",
  MONTH_MUST_BE_BETWEEN: "Month must be between 0 and 11",
  MRN_IS_REQUIRED: "MR No is required",
  MRN_MUST_BE_ALPHANUMERIC_AND_CANNOT_ZERO:
    "MR No must be alphanumeric and Cannot be zero",
  UHID_IS_REQUIRED: "UHID is required",
  UHID_MUST_BE_ALPHANUMERIC: "UHID must be alphanumeric and Cannot be zero",
  PEI_IS_REQUIRED: "Pre-existing infections is required",
  NOTES_REQUIRED: "Notes are required for 'Others'",
  DAYS_MUST_BE_BETWEEN: "Days must be between 1 and 31",
  ENTER_VALID_MRN: "Enter valid MR number",
  ENTER_VALID_UHID: "Enter valid UHID",
};

export const ALL_REGEX = {
  EMAIL_REGEX: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  NAME_REGEX: /^(?!.*\s{2,})[a-zA-Z.\s]+$/,
  AGE_REGEX: /^(?:[1-9]|[1-9][0-9])$/,
  MRN_REGEX: /^(?!.*\s{2,})(?!0+$)[a-zA-Z0-9.\s]+$/,
  UHID_REGEX: /^(?!0+$)(?!.*\s{2,})[a-zA-Z0-9.\s]+$/,
  YEAR_REGEX: /^(?:\d|[1-9]\d|1[01]\d|12[0-5])$/,
  MONTH_REGEX: /^(0?[0-9]|1[0-1])$/,
  DAYS_REGEX: /^(?:[1-9]|[12][0-9]|3[01])$/,
};

export const LOG_TYPES = {
  BLOOD_BANK: "Blood_Bank",
  PAC: "PAC",
};

export const FILTER_PARAMETERS = {
  PRE_OT_ENTRY_FILTER_PARAMETER: "preOtEntryFilterParameter",
  POST_OT_ENTRY_FILTER_PARAMETER: "postOtEntryFilterParameter",
};
