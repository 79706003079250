import Login from "../Pages/login";
import HomePage from "../Pages/HomePage";
import NewMySchedules from "../Pages/NewMySchedules";
import NewBookingRequest from "../Pages/NewBookingRequest";
import RequestDetails from "../Pages/MyRequests/RequestDetails";
import RequestSuccess from "../Pages/NewBookingRequest/RequestSuccess";
import MyRequests from "../Pages/MyRequests";
import ScheduleDetails from "../Pages/NewMySchedules/ScheduleDetails";
import QuickBookingRequest from "../Pages/QuickBookingRequest";
import BookingOverview from "../Pages/BookingOverview";
import PreOtEntry from "../Pages/StatusUpdate/PreOtEntry";
import PostOtStatus from "../Pages/StatusUpdate/PostOtStatus";

export default {
  Login,
  HomePage,
  NewMySchedules,
  NewBookingRequest,
  RequestDetails,
  RequestSuccess,
  MyRequests,
  ScheduleDetails,
  QuickBookingRequest,
  BookingOverview,
  PreOtEntry,
  PostOtStatus,
};
