import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaCalendarCheck } from "react-icons/fa";
import { permissions } from "../../helpers/permission";
import { LogoutModal } from "../LogoutModal";
import Greeting from "./Greeting";
import {
  getItemFromLocalStorage,
  history,
  useClearLocalStorageExceptFilters,
} from "../../helpers/utils";
import { GLOBAL_CONSTANTS, WORDINGS } from "../../helpers/constants";
import titleImage from "../../assets/titleImage.png";
import headerImage from "../../assets/headerImage.png";
import logOut from "../../assets/Icons/log-out-03.svg";
import styles from "./index.module.css";
import NewToaster from "../../components/NewToaster";
import { resetInputActions } from "../QuickBookingRequest/action";

const HomePage = () => {
  const dispatch = useDispatch();
  const {
    is_crew_member: isCrewMember,
    privileges = {},
    designation = "",
  } = getItemFromLocalStorage("user") || {};
  const { totalCount } = useSelector((state) => state.myScheduleReducer);
  const { totalCount: bookingCount } = useSelector(
    (state) => state.bookingReducer
  );

  useClearLocalStorageExceptFilters([]);

  useEffect(() => {
    dispatch(resetInputActions());
  }, []);

  const tabs = [
    {
      key: "quickBooking",
      label: WORDINGS.REQUEST_OT,
      privilege: designation === GLOBAL_CONSTANTS.SURGEON,
      icon: null,
      totalCount: 0,
      page: "quickBookingRequest",
    },
    {
      key: "requestOt",
      label: WORDINGS.REQUEST_OT,
      privilege:
        permissions.OT_SCHEDULE_REQUEST_CREATE in privileges &&
        designation !== GLOBAL_CONSTANTS.SURGEON,
      icon: null,
      totalCount: 0,
      page: "newBookingRequest",
    },
    {
      key: "bookings",
      label: WORDINGS.MY_REQUESTS,
      privilege:
        permissions.OT_SCHEDULE_REQUEST_CREATE in privileges ||
        permissions.OT_SCHEDULE_REQUEST_VIEW in privileges,
      icon: null,
      totalCount: bookingCount,
      page: "myRequests",
    },
    {
      key: "mySchedules",
      label: WORDINGS.MY_SCHEDULES,
      privilege:
        permissions.OT_CREW_MEMBER_DATA_VIEW in privileges && isCrewMember,
      icon: <FaCalendarCheck />,
      totalCount: totalCount,
      page: "newMySchedules",
    },
    {
      key: "bookingOverview",
      label: WORDINGS.REQUEST_OVERVIEW,
      privilege: true,
      icon: null,
      totalCount: 0,
      page: "bookingOverview",
    },
    {
      key: "preOtEntry",
      label: WORDINGS.LOG_PRE_OT_ENTRY,
      privilege:
        permissions.SURGERY_STATUS_REAL_TIME_PRE_OP_UPDATE in privileges,
      icon: null,
      totalCount: 0,
      page: "preOtEntry",
    },
    {
      key: "postOtStatus",
      label: WORDINGS.LOG_POST_OT_STATUS,
      privilege:
        permissions.SURGERY_STATUS_REAL_TIME_POST_OP_UPDATE in privileges,
      icon: null,
      totalCount: 0,
      page: "postOtStatus",
    },
    // {
    //   key: "notifications",
    //   label: "Notifications",
    //   privilege: false,
    //   icon: <IoNotifications />,
    //   totalCount: 0,
    //   page: "Mainhome",
    // },
  ];

  const [isLogout, toggleLogout] = useState(false);
  const { message } = useSelector((state) => state.newToasterReducer);

  return (
    <div className={styles["home-page"]}>
      {message && <NewToaster />}
      <div className={styles["home-page__theme"]}>
        <div>
          <div className={styles["home-page__header-img"]}>
            <img src={headerImage} alt="header_image" />
          </div>
          <div className={styles["home-page__info"]}>
            <img src={logOut} onClick={() => toggleLogout(true)} alt="logout" />
          </div>
        </div>
        <div className={styles["home-page__title-bar"]}>
          <div>
            <Greeting />
            <p>{WORDINGS.WHAT_TO_DO_TODAY}</p>
          </div>
          <div>
            <img src={titleImage} alt="title_image" />
          </div>
        </div>
        <div className={styles["home-page__tabs-container"]}>
          {tabs.map(
            (item) =>
              item.privilege && (
                <div
                  className={styles["home-page__header-tab"]}
                  onClick={() => {
                    history.replace(`/${item.page}`);
                  }}
                  key={item.key}
                >
                  <div>
                    <label> {item.label}</label>
                    {/* {item.totalCount === 0 ? (
                      ""
                    ) : (
                      <span>{item.totalCount}</span>
                    )} */}
                  </div>
                </div>
              )
          )}
        </div>
      </div>
      {isLogout && (
        <LogoutModal isLogout={isLogout} toggleLogout={toggleLogout} />
      )}
    </div>
  );
};

export default HomePage;
