import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { FaLocationDot } from "react-icons/fa6";
import {
  DATE_TIME_FORMAT,
  FILTER_PARAMETERS,
  GLOBAL_CONSTANTS,
  GLOBAL_MESSAGES,
  PAGE_DATA,
  WORDINGS,
} from "../../../helpers/constants";
import {
  getItemFromLocalStorage,
  removeUnderScore,
  setLocalstorageItem,
} from "../../../helpers/utils";
import Loader from "../../../components/Loader";
import DetailCard from "../../../components/DetailCard";
import Modal from "../../../components/Modal";
import Pagination from "../../../components/Pagination";
import AdvancedDropdown from "../../../components/AdvancedDropdown";
import { getAreas, getSchedules } from "../actions/statusUpdateAction";
import {
  FilterTaboptions,
  STATUS_UPDATE_CONSTANTS,
  STATUS_UPDATE_FILTER_CONSTANTS,
} from "../constants";
import { StatusUpdateConfirmationModal } from "../StatusUpdateConfirmationModal";
import styles from "./index.module.css";
import { SliderTabFilter } from "../../../components/SliderTabFilter";

const PreOtEntry = () => {
  const dispatch = useDispatch();
  const { units = [] } = getItemFromLocalStorage(GLOBAL_CONSTANTS.USER) || {};
  const operativeAreaFilter =
    getItemFromLocalStorage(PAGE_DATA.OPERATIVE_AREA_FILTER_PRE_OT) || null;
  const timeStatus =
    getItemFromLocalStorage(FILTER_PARAMETERS.PRE_OT_ENTRY_FILTER_PARAMETER) ||
    STATUS_UPDATE_FILTER_CONSTANTS.UPCOMING;

  const { schedules, totalCount } = useSelector(
    (state) => state.statusUpdateReducer
  );
  const { operativeAreaList } = useSelector(
    (state) => state.statusUpdateReducer
  );

  const [loader, setLoader] = useState(true);
  const [statusUpdate, setStatusUpdate] = useState(false);
  const [selectedName, setSelectedName] = useState("");
  const [scheduleId, setScheduleId] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [operativeArea, setOperativeArea] = useState(
    operativeAreaFilter || null
  );
  const [timeFIlter, setTimeFilter] = useState(timeStatus);

  const params = {
    page: pageNumber,
    size: 10,
    sort: 1,
    ignorePaging: false,
  };

  useEffect(() => {
    if (operativeArea) {
      setLoader(true);
      dispatch(
        getSchedules(
          units[0].id,
          setLoader,
          params,
          operativeArea.id,
          STATUS_UPDATE_CONSTANTS.PRE_OT,
          timeFIlter
        )
      );
    }
    return () => {
      dispatch({ type: STATUS_UPDATE_CONSTANTS.CLEAR_SCHEDULES });
    };
  }, [pageNumber, operativeArea, timeFIlter]);

  useEffect(() => {
    dispatch(getAreas(units[0].id, STATUS_UPDATE_CONSTANTS.PRE_OT, setLoader));
  }, []);

  const handleClose = () => {
    setStatusUpdate(false);
    setScheduleId("");
    setSelectedName("");
  };

  const handleDropdownChange = (data) => {
    setOperativeArea(data);
    localStorage.setItem(
      PAGE_DATA.OPERATIVE_AREA_FILTER_PRE_OT,
      JSON.stringify(data)
    );
  };

  const handleFilterClick = (data) => {
    setTimeFilter(data);
    setLocalstorageItem(FILTER_PARAMETERS.PRE_OT_ENTRY_FILTER_PARAMETER, data);
  };

  return (
    <div className={styles["pre-ot-entry"]}>
      {statusUpdate && (
        <Modal
          isOpen={statusUpdate}
          onCancel={() => handleClose()}
          className={styles["custom-modal"]}
        >
          <StatusUpdateConfirmationModal
            onCancel={() => handleClose()}
            name={selectedName}
            scheduleId={scheduleId}
            status={STATUS_UPDATE_CONSTANTS.PRE_OP}
            params={params}
            operativeArea={operativeArea.name}
            operativeAreaId={operativeArea?.id}
            type={STATUS_UPDATE_CONSTANTS.PRE_OT}
            timeFilter={timeFIlter}
          />
        </Modal>
      )}
      <div className={styles["pre-ot-entry-title-container"]}>
        <h2 className={styles["pre-ot-entry-title"]}>
          {WORDINGS.PRE_OT_ENTRY}
        </h2>
        <p className={styles["pre-ot-entry-date"]}>
          {moment().format(DATE_TIME_FORMAT.Do_MMM_YYYY)}
        </p>
      </div>
      <SliderTabFilter
        onClick={handleFilterClick}
        selected={timeFIlter}
        options={FilterTaboptions}
      />
      <div className={styles["pre-ot-entry-dropdown-container"]}>
        <p className={styles["pre-ot-entry-dropdown-label"]}>
          <FaLocationDot fontSize={16} color="#0E376E" />
          {WORDINGS.PRE_OT_AREA}
        </p>
        <AdvancedDropdown
          className={styles["pre-ot-entry-dropdown"]}
          placeholder={GLOBAL_MESSAGES.SELECT}
          options={operativeAreaList}
          value={operativeArea}
          onChange={(value) => handleDropdownChange(value)}
        />
        {!operativeArea && (
          <p className={styles["pre-ot-entry-error-msg"]}>
            {WORDINGS.PLEASE_SELECT_PRE_OT_AREA}
          </p>
        )}
      </div>

      {totalCount > 10 && (
        <Pagination
          totalCount={totalCount}
          listCount={schedules?.length}
          page={pageNumber}
          setPage={setPageNumber}
          rowsPerPage={10}
        />
      )}
      {loader ? (
        <div className={styles["pre-ot-entry-loader-container"]}>
          <Loader />
        </div>
      ) : (
        <div className={styles["pre-ot-data-cards-container"]}>
          {totalCount > 0 ? (
            schedules.map((schedule) => (
              <DetailCard
                patientName={schedule?.patient_name}
                key={schedule?.id}
                ot={schedule?.ot?.name}
                time={`${moment(schedule?.surgery_start_time).format(
                  DATE_TIME_FORMAT.hh_mm_A
                )} - ${moment(schedule?.surgery_end_time).format(
                  DATE_TIME_FORMAT.hh_mm_A
                )}`}
                speciality={schedule?.speciality?.name}
                surgery={schedule?.types_of_surgeries}
                // handleClick={() => viewDetails(schedule?.id)}
                statusUpdate={setStatusUpdate}
                setSelectedName={() => setSelectedName(schedule?.patient_name)}
                setScheduleId={() => setScheduleId(schedule?.id)}
                noShadow
              />
            ))
          ) : (
            <div className={styles["pre-ot-entry-error-div"]}>
              <p>{`${
                WORDINGS.NO_SURGERIES_SCHEDULED_FOR_THIS
              } ${removeUnderScore(WORDINGS.PRE_OT)}`}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default PreOtEntry;
