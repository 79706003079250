import React from "react";

const BedIcon = ({ className, style }) => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 31 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.4052 6.75L10.5922 6.75C10.3496 6.75 10.2283 6.75 10.1145 6.76862C9.59137 6.85418 9.1107 7.23003 8.7718 7.81856C8.69804 7.94663 8.63077 8.09407 8.49623 8.38894C8.2886 8.84399 8.18478 9.07152 8.14228 9.24532C7.93812 10.0802 8.27148 10.9903 8.86019 11.2053C8.98274 11.25 9.16989 11.25 9.5442 11.25L27.4532 11.25C27.8275 11.25 28.0147 11.25 28.1372 11.2053C28.7259 10.9903 29.0593 10.0802 28.8551 9.24532C28.8126 9.07153 28.7088 8.84394 28.5012 8.38894C28.3666 8.09411 28.2993 7.94662 28.2256 7.81856C27.8867 7.23003 27.406 6.85418 26.8829 6.76862C26.769 6.75 26.6478 6.75 26.4052 6.75Z"
        stroke="#D9D9D9"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M13.8125 19.6875L25.7917 11.25"
        stroke="#D9D9D9"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M23.1862 19.6875L11.207 11.25"
        stroke="#D9D9D9"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M14.3327 21.375C14.3327 22.6176 13.3999 23.625 12.2493 23.625C11.0988 23.625 10.166 22.6176 10.166 21.375C10.166 20.1324 11.0988 19.125 12.2493 19.125C13.3999 19.125 14.3327 20.1324 14.3327 21.375Z"
        stroke="#D9D9D9"
        stroke-width="1.5"
      />
      <path
        d="M26.8327 21.375C26.8327 22.6176 25.8999 23.625 24.7493 23.625C23.5988 23.625 22.666 22.6176 22.666 21.375C22.666 20.1324 23.5988 19.125 24.7493 19.125C25.8999 19.125 26.8327 20.1324 26.8327 21.375Z"
        stroke="#D9D9D9"
        stroke-width="1.5"
      />
      <path
        d="M12.25 6.75V4.61655C12.25 3.80923 12.8869 3.21686 13.5443 3.41266L15.6276 4.03313C16.0914 4.17124 16.4167 4.66759 16.4167 5.23702V6.75"
        stroke="#D9D9D9"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <line
        x1="5.53906"
        y1="14.75"
        x2="12.0006"
        y2="14.75"
        stroke="#D9D9D9"
        stroke-width="0.5"
      />
      <line
        x1="2.76953"
        y1="15.75"
        x2="11.0772"
        y2="15.75"
        stroke="#D9D9D9"
        stroke-width="0.5"
      />
      <line
        y1="16.75"
        x2="10.1538"
        y2="16.75"
        stroke="#D9D9D9"
        stroke-width="0.5"
      />
    </svg>
  );
};

export default BedIcon;
