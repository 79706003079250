import React, { useEffect, useState } from "react";
import {
  BUTTON_TYPES,
  GLOBAL_MESSAGES,
  WORDINGS,
} from "../../../helpers/constants";
import NewButton from "../../../components/NewButton";
import styles from "./index.module.css";
import AdvancedDropdown from "../../../components/AdvancedDropdown";
import EquipmentModelUpdate from "./EquipmentModelUpdate";
import Modal from "../../../components/Modal";
import { CgTrash } from "react-icons/cg";
import editIcon from "../../../assets/Icons/editIcon.svg";

const EquipmentModal = (props) => {
  const {
    eqModels,
    equipmentModels,
    isEquipmentModelUpdate,
    isEquipmentModelDelete,
    toggleEquipmentModelUpdate,
    setSelectedEquipmentModel,
    toggleEquipmentModelDelete,
    selectedEquipmentModel,
    setEquipmentModels,
    toggleEquipment,
  } = props;

  const [tempEquipmentModals, setTempEquipmentModals] =
      useState(equipmentModels),
    [equipmentQty, setEquipmentQty] = useState("1"),
    [equipmentModel, setEquipmentModel] = useState(null);

  useEffect(() => {
    if (equipmentModel) {
      if (tempEquipmentModals[equipmentModel?.id]) {
        setTempEquipmentModals({
          ...tempEquipmentModals,
          [equipmentModel?.id]: {
            ...equipmentModel,
            quantity:
              +equipmentQty + tempEquipmentModals[equipmentModel?.id]?.quantity,
          },
        });
      } else {
        setTempEquipmentModals({
          ...tempEquipmentModals,
          [equipmentModel?.id]: {
            ...equipmentModel,
            quantity: +equipmentQty,
          },
        });
      }

      setEquipmentModel(null);
    }
  }, [equipmentModel]);

  const handleDeleteModel = () => {
    const data = {};

    Object.values(tempEquipmentModals).forEach((item) => {
      if (selectedEquipmentModel.id !== item?.id) {
        data[item.id] = { ...item };
      }
    });
    setTempEquipmentModals({ ...data });
    toggleEquipmentModelDelete(false);
  };

  return (
    <div className={styles["surgery-details__select-equipment"]}>
      <AdvancedDropdown
        value={equipmentModel}
        onChange={(value) => setEquipmentModel(value)}
        options={eqModels.filter((model) => !tempEquipmentModals[model?.id])}
        placeholder={GLOBAL_MESSAGES.SELECT}
      />
      <div className={styles["surgery-details__select-equipment-list"]}>
        {Object.keys(tempEquipmentModals).length > 0 ? (
          <div className={styles["surgery-details__equipments"]}>
            {Object.values(tempEquipmentModals)
              ?.reverse()
              ?.map((model) => (
                <div
                  key={model?.id}
                  className={styles["surgery-details__equipment"]}
                >
                  <div>{model?.name}</div>
                  <div
                    onClick={() => {
                      toggleEquipmentModelUpdate(true);
                      setSelectedEquipmentModel(model);
                    }}
                    className={styles["surgery-details__equipment-edit-btn"]}
                  >
                    <div>{model?.quantity}</div>
                    <img src={editIcon} alt="edit" />
                  </div>
                  <div
                    onClick={() => {
                      toggleEquipmentModelDelete(true);
                      setSelectedEquipmentModel(model);
                    }}
                    className={styles["surgery-details__equipment-delete-btn"]}
                  >
                    <CgTrash color="#B51616" fontSize={24} />
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <p className={styles["surgery-details__equipment-not-selected"]}>
            {GLOBAL_MESSAGES.NO_SELECTED_EQUIPMENTS}
          </p>
        )}
        {isEquipmentModelUpdate && (
          <EquipmentModelUpdate
            isOpen={isEquipmentModelUpdate}
            toggleOpen={toggleEquipmentModelUpdate}
            equipmentModel={selectedEquipmentModel}
            setEquipmentModel={setSelectedEquipmentModel}
            equipmentModels={tempEquipmentModals}
            setEquipmentModels={setTempEquipmentModals}
          />
        )}
        {isEquipmentModelDelete && (
          <Modal
            isOpen={isEquipmentModelDelete}
            onCancel={() => toggleEquipmentModelDelete(false)}
          >
            <div className={styles["equipment-model-delete"]}>
              <div className={styles["equipment-model-delete__title"]}>
                <span>{`${GLOBAL_MESSAGES.REMOVE} "${selectedEquipmentModel?.name}" ?`}</span>
              </div>
              <div
                className={styles["equipment-model-delete__button-container"]}
              >
                <NewButton onClick={() => handleDeleteModel()}>
                  {BUTTON_TYPES.REMOVE}
                </NewButton>
                <NewButton
                  cancelButton
                  onClick={() => toggleEquipmentModelDelete(false)}
                >
                  {BUTTON_TYPES.CANCEL}
                </NewButton>
              </div>
            </div>
          </Modal>
        )}
      </div>
      <div className={styles["surgery-details__select-equipment-button"]}>
        <NewButton
          largeButton
          onClick={() => {
            toggleEquipment(false);
            setEquipmentModels(tempEquipmentModals);
          }}
        >
          {WORDINGS.SAVE_CHANGES}
        </NewButton>
      </div>
    </div>
  );
};

export default EquipmentModal;
