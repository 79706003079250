import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import { STATUS_UPDATE_FILTER_CONSTANTS } from "../../Pages/StatusUpdate/constants";
import { WORDINGS } from "../../helpers/constants";
import { joinClass } from "../../helpers/utils";
import { HiColorSwatch } from "react-icons/hi";

export const SliderTabFilter = ({
  options,
  onClick,
  selected,
  backgroundColor,
  sliderColor,
}) => {
  const dayRef = useRef(null);
  const [optionWidth, setOptionWidth] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(
    options?.findIndex((option) => option.value === selected)
  );

  const optionContainerStyle = {
    backgroundColor: backgroundColor ? backgroundColor : "#c5d2e4ce",
    gridTemplateColumns: `repeat(${options.length},1fr)`,
  };
  const optionStyle = {};

  const sliderStyle = {
    backgroundColor: sliderColor ? sliderColor : "#0e376e",
    width: `calc(100% / ${options.length})`,
    transform: `translateX(${selectedIndex * optionWidth}px)`,
    transition: "transform 0.4s ease-out",
  };

  useEffect(() => {
    if (dayRef.current) {
      setOptionWidth(dayRef.current.offsetWidth);
    }
  }, []);

  const handleOnClick = (option, index) => {
    onClick(option.value);
    setSelectedIndex(index);
  };

  return (
    <div style={optionContainerStyle} className={styles["sliding-time-filter"]}>
      {options?.map((option, index) => (
        <div
          style={optionStyle}
          className={joinClass(
            styles["sliding-time-filter__option"],
            selectedIndex === index && styles["sliding-time-filter__selected"]
          )}
          key={index}
          onClick={() => handleOnClick(option, index)}
          ref={index === 0 ? dayRef : null}
        >
          {option.label}
        </div>
      ))}
      <span
        style={sliderStyle}
        className={styles["sliding-time-filter__slider"]}
      >
        <p></p>
      </span>
    </div>
  );
};
