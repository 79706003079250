import { STATUS_UPDATE_CONSTANTS } from "../constants";

const initialState = {
  totalCount: 0,
  schedules: [],
  operativeAreaList: [],
};

export const statusUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case STATUS_UPDATE_CONSTANTS.GET_OT_SUCCESS:
      return {
        ...state,
        schedules: action.payload,
        totalCount: action.totalCount,
      };
    case STATUS_UPDATE_CONSTANTS.GET_OPERATIVE_AREA_SUCCESS:
      return {
        ...state,
        operativeAreaList: action.payload,
      };
    case STATUS_UPDATE_CONSTANTS.CLEAR_SCHEDULES:
      return {
        ...state,
        schedules: [],
        totalCount: 0,
      };
    default:
      return state;
  }
};
