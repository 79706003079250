import React from "react";
import scissors from "../../assets/Icons/scissors-cut-01.svg";
import clock from "../../assets/Icons/clock.svg";
import location from "../../assets/Icons/location.svg";
import hospital from "../../assets/Icons/hospital.svg";
import styles from "./index.module.css";
import { joinClass } from "../../helpers/utils";
import { PiUser } from "react-icons/pi";
import { FaUserCheck } from "react-icons/fa";
import { GLOBAL_CONSTANTS } from "../../helpers/constants";
import BedIcon from "../../assets/Icons/icons";

const DetailCard = ({
  patientName,
  ot,
  time,
  speciality,
  surgery,
  handleClick,
  statusUpdate,
  setSelectedName,
  setScheduleId,
  page,
  noShadow,
}) => {
  const handleButtonClick = () => {
    statusUpdate(true);
    setSelectedName();
    setScheduleId();
  };

  return (
    <div
      className={joinClass(
        styles["detail-card"],
        !noShadow && styles["detail-card-shadow"]
      )}
      onClick={handleClick}
    >
      <div className={joinClass(styles["detail-card__contents-container"])}>
        <div className={styles["detail-card__item"]}>
          <PiUser fontSize={24} color="#0E376E" />
          <label>{patientName}</label>
        </div>

        <div className={styles["detail-card__item"]}>
          <img src={location} alt="OT" />
          <label>{ot}</label>
        </div>
        {time && (
          <div className={styles["detail-card__item"]}>
            <img src={clock} alt="time" />
            <label>{time}</label>
          </div>
        )}
        <div className={styles["detail-card__item"]}>
          <img src={hospital} alt="speciality" />
          <label>{speciality}</label>
        </div>
        <div
          className={joinClass(
            styles["detail-card__item"],
            surgery?.length > 1 && styles["detail-card__last"]
          )}
        >
          <img src={scissors} alt="surgery" />
          {surgery?.length > 1 ? (
            <label>
              {surgery?.map((surgery, index) => (
                <div key={index}>
                  {index + 1}. {surgery.name}
                </div>
              ))}
            </label>
          ) : (
            <label>
              {surgery?.map((surgery, index) => (
                <div key={index}>{surgery.name}</div>
              ))}
            </label>
          )}
        </div>
      </div>

      <div className={styles["detail-card-mark-btn-container"]}>
        {statusUpdate && (
          <div
            className={styles["detail-card-mark-btn"]}
            onClick={() => handleButtonClick()}
          >
            {page === GLOBAL_CONSTANTS.POST_OT ? (
              <BedIcon />
            ) : (
              <FaUserCheck fontSize={24} color="#c4ddf7" />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DetailCard;
