import {
  ALL_REGEX,
  PATIENT_FIELDS,
  VALIDATION_MESSEGES,
} from "../../helpers/constants";

export const validatePersonalData = ({
  patientName,
  age,
  year,
  month,
  days,
  mrNumber,
  uhidNumber,
  mandatoryFields,
  includedFields,
  setErrorMsg,
}) => {
  let isValid = true;
  const errors = { patientName: "", age: "", mrn: "", uhid: "" };

  if (!patientName) {
    errors.patientName = VALIDATION_MESSEGES.PATIENT_NAME_IS_REQUIRED;
    isValid = false;
  } else if (!ALL_REGEX.NAME_REGEX.test(patientName)) {
    errors.patientName = VALIDATION_MESSEGES.ENTER_VALID_NAME;
    isValid = false;
  }

  if (includedFields?.[PATIENT_FIELDS.AGE]) {
    if (mandatoryFields?.[PATIENT_FIELDS.AGE] && !age) {
      errors.age = VALIDATION_MESSEGES.AGE_IS_REQUIRED;
      isValid = false;
    } else if (age) {
      if (year && !ALL_REGEX.YEAR_REGEX.test(year)) {
        errors.age = VALIDATION_MESSEGES.ENTER_VALID_AGE;
        isValid = false;
      }
      if (month && !ALL_REGEX.MONTH_REGEX.test(month)) {
        errors.age = VALIDATION_MESSEGES.MONTH_MUST_BE_BETWEEN;
        isValid = false;
      }
      if (days && !ALL_REGEX.DAYS_REGEX.test(days)) {
        errors.age = VALIDATION_MESSEGES.DAYS_MUST_BE_BETWEEN;
        isValid = false;
      }
      if (year === "0") {
        if (!ALL_REGEX.MONTH_REGEX.test(month)) {
          errors.age = VALIDATION_MESSEGES.MONTH_MUST_BE_BETWEEN;
          isValid = false;
        }
        if (month === "0" && !ALL_REGEX.DAYS_REGEX.test(days)) {
          errors.age = VALIDATION_MESSEGES.DAYS_MUST_BE_BETWEEN;
          isValid = false;
        }
      }
      if (!year && month === "0" && !ALL_REGEX.DAYS_REGEX.test(days)) {
        errors.age = VALIDATION_MESSEGES.DAYS_MUST_BE_BETWEEN;
        isValid = false;
      }
    }
  }

  if (includedFields?.[PATIENT_FIELDS.MRN]) {
    if (mandatoryFields?.[PATIENT_FIELDS.MRN] && !mrNumber) {
      errors.mrn = VALIDATION_MESSEGES.MRN_IS_REQUIRED;
      isValid = false;
    } else if (mrNumber && !ALL_REGEX.MRN_REGEX.test(mrNumber)) {
      errors.mrn = VALIDATION_MESSEGES.ENTER_VALID_MRN;
      isValid = false;
    }
  }

  if (includedFields?.[PATIENT_FIELDS.UHID]) {
    if (mandatoryFields?.[PATIENT_FIELDS.UHID] && !uhidNumber) {
      errors.uhid = VALIDATION_MESSEGES.UHID_IS_REQUIRED;
      isValid = false;
    } else if (uhidNumber && !ALL_REGEX.UHID_REGEX.test(uhidNumber)) {
      errors.uhid = VALIDATION_MESSEGES.ENTER_VALID_UHID;
      isValid = false;
    }
  }

  setErrorMsg(errors);
  return isValid;
};

export function validateInfectionStatus({
  selectedSwc,
  selectedPei,
  mandatoryFields,
  setErrorMsg,
  notes,
}) {
  let isValid = true;
  const errors = { swc: "", pei: "", peiNotes: "" };

  // if (mandatoryFields?.[PATIENT_FIELDS.SSI] && !selectedSwc) {
  //   isValid = false;
  //   errors.swc = "SWC is required.";
  // }

  if (mandatoryFields?.[PATIENT_FIELDS.PEI]) {
    if (!selectedPei) {
      isValid = false;
      errors.pei = VALIDATION_MESSEGES.PEI_IS_REQUIRED;
    } else if (selectedPei?.name === "Others" && notes?.length === 0) {
      isValid = false;
      errors.peiNotes = VALIDATION_MESSEGES.NOTES_REQUIRED;
    }
  } else {
    if (selectedPei) {
      if (selectedPei?.name === "Others" && notes?.length === 0) {
        isValid = false;
        errors.peiNotes = VALIDATION_MESSEGES.NOTES_REQUIRED;
      }
    }
  }
  setErrorMsg(errors);
  return isValid;
}

export function validateAnaesthesia({ selectedTypes, isPac, mandatoryFields }) {
  let isValid = true;
  if (mandatoryFields?.[PATIENT_FIELDS.ANAESTHESIA]) {
    isValid = selectedTypes?.length > 0 && isPac;
  }
  return isValid;
}
