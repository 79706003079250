import React from "react";
import { GLOBAL_CONSTANTS, WORDINGS } from "../../../helpers/constants";
import styles from "./index.module.css";
import NewButton from "../../../components/NewButton";
import { useDispatch } from "react-redux";
import { getItemFromLocalStorage } from "../../../helpers/utils";
import { updateOtStatus } from "../actions/statusUpdateAction";
import { STATUS_UPDATE_CONSTANTS } from "../constants";

export const StatusUpdateConfirmationModal = ({
  name,
  onCancel,
  scheduleId,
  status,
  params,
  operativeArea,
  operativeAreaId,
  type,
  timeFilter,
}) => {
  const dispatch = useDispatch();
  const { units = [] } = getItemFromLocalStorage(GLOBAL_CONSTANTS.USER) || {};
  const handleButtonClick = () => {
    if (scheduleId) {
      dispatch(
        updateOtStatus(
          units[0].id,
          scheduleId,
          onCancel,
          status,
          params,
          operativeAreaId,
          type,
          timeFilter
        )
      );
    }
  };
  return (
    <div className={styles["status-update-modal"]}>
      {status === STATUS_UPDATE_CONSTANTS.PRE_OP && (
        <p>{`${WORDINGS.statusUpdateConfirmationMessegePreOT} ${name}?`}</p>
      )}
      {status === STATUS_UPDATE_CONSTANTS.PATIENT_SHIFTED && (
        <p>{`${WORDINGS.wouldYouLikeToMark} ${name} ${WORDINGS.hasBeenShiftedFrom} ${operativeArea}?`}</p>
      )}
      <div className={styles["status-update-modal-buttons"]}>
        <NewButton
          className={styles["status-update-modal-btn"]}
          onClick={onCancel}
          cancelButton
        >
          {WORDINGS.NO}
        </NewButton>
        <NewButton
          className={styles["status-update-modal-btn"]}
          smallTabButtonActive
          onClick={handleButtonClick}
        >
          {WORDINGS.YES}
        </NewButton>
      </div>
    </div>
  );
};
