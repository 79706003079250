import { WORDINGS } from "../../helpers/constants";

export const STATUS_UPDATE_CONSTANTS = {
  GET_OT_SUCCESS: "GET_PRE_OT_SUCCESS",
  GET_OT_FAILED: "GET_PRE_OT_FAILED",
  CLEAR_SCHEDULES: "CLEAR_SCHEDULES",
  GET_STATUS_UPDATE_SUCCESS: "GET_STATUS_UPDATE_SUCCESS",
  PRE_OT: "pre-ot",
  PRE_OP: "pre_op",
  PATIENT_SHIFTED: "patient_shifted",
  POST_OT: "post-ot",
  GET_OPERATIVE_AREA_SUCCESS: "GET_OPERATIVE_AREA_SUCCESS",
  GET_OPERATIVE_AREA_FETCH_FAILED: "GET_OPERATIVE_AREA_FETCH_FAILED",
};

export const STATUS_UPDATE_FILTER_CONSTANTS = {
  ALL: "all",
  UPCOMING: "upcoming",
  OVERDUE: "overdue",
};

export const FilterTaboptions = [
  {
    id: 1,
    name: STATUS_UPDATE_FILTER_CONSTANTS.UPCOMING,
    value: STATUS_UPDATE_FILTER_CONSTANTS.UPCOMING,
    label: WORDINGS.UPCOMING,
  },
  {
    id: 2,
    name: STATUS_UPDATE_FILTER_CONSTANTS.OVERDUE,
    value: STATUS_UPDATE_FILTER_CONSTANTS.OVERDUE,
    label: WORDINGS.OVERDUE,
  },
  {
    id: 3,
    name: STATUS_UPDATE_FILTER_CONSTANTS.ALL,
    value: STATUS_UPDATE_FILTER_CONSTANTS.ALL,
    label: WORDINGS.ALL,
  },
];
