import React, { useEffect, useMemo, useState } from "react";
import { FaClock, FaHospital, FaStethoscope } from "react-icons/fa";
import moment from "moment";
import { createBookingRequest } from "../../BookingOverview/actions/bookingActions";
import NewButton from "../../../components/NewButton";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CalendarIcon } from "../../../assets/Icons/calendar.svg";
import { RiScissorsCutFill } from "react-icons/ri";
import userIcon from "../../../assets/Icons/userIcon.svg";
import Modal from "../../../components/Modal";
import NewLoader from "../../../components/NewLoader";
import {
  BOOKING_MODE,
  BOOKING_TYPE,
  BUTTON_TYPES,
  DATE_TIME_FORMAT,
  GLOBAL_CONSTANTS,
  RADIO_CONSTANTS,
  SURGERY_TYPES,
  WORDINGS,
} from "../../../helpers/constants";
import styles from "./index.module.css";
import { setTitle } from "../action";
import {
  checkObjectNotEmpty,
  getItemFromLocalStorage,
  joinClass,
  removeUnderScore,
} from "../../../helpers/utils";
import { TbDropletPlus } from "react-icons/tb";

const QuickRequestDetails = (props) => {
  const dispatch = useDispatch();
  const { units = [] } = getItemFromLocalStorage("user") || {};

  const {
    specialityInfo: { chiefSurgeon, speciality },
    surgeryDate,
    surgeryDetails: {
      startTime,
      endTime,
      procedureTime,
      bufferTime,
      bookingMode,
      crewDetails,
      equipmentModels,
      pharmacyNotes,
      surgeryList,
      surgeryClassification,
    },
    otDetails,
    personalDetails: { patientName, age, mrn, uhid },
    includedFields,
    mandatoryFields,
    infectionStatus: { ssi, pei },
    patientNotes,
    anaesthesia: { anaesthesiaTypes, pac },
    confirmations,
    bloodBankAssist,
    specialItem: { isSpecialItems, specialPharmacy: specialPharmacyList = [] },
  } = useSelector((state) => state.quickInputReducer);

  const [listEquipments, toggleListEquipments] = useState(false);
  const [listPharmacy, toggleListPharmacy] = useState(false);
  const [isRequest, toggleRequest] = useState(false);
  const [isEmergency, setIsEmergency] = useState(false);

  useEffect(() => {
    dispatch(setTitle(WORDINGS.REQUEST_OVERVIEW));
  }, []);

  useEffect(() => {
    if (otDetails?.available_mode === BOOKING_TYPE.EMERGENCY) {
      setIsEmergency(true);
    } else {
      setIsEmergency(false);
    }
  }, [otDetails]);

  const checkIsMajor = useMemo(() => {
    if (surgeryList) {
      return surgeryList.some((surgery) => surgery.is_major)
        ? SURGERY_TYPES.MAJOR
        : SURGERY_TYPES.MINOR;
    }
  }, [surgeryList]);

  const handleRequest = () => {
    const newCrewDetails = {};
    const equipmentRequest = {};
    const confirmationLogs = new Set();

    Object.keys(crewDetails)?.forEach((key) => {
      newCrewDetails[surgeryList[0]?.crew_type?.id] = {
        ...newCrewDetails[surgeryList[0]?.crew_type?.id],
        [key]: +crewDetails[key],
      };
    });

    Object.keys(equipmentModels)?.forEach((item) => {
      equipmentRequest[item] = {
        quantity: +equipmentModels[item]?.quantity,
      };
    });

    const newPharmacyStandards = [];
    const newCssdKits = [];

    for (const surgery of surgeryList) {
      if (surgery?.pharmacy_standard?.pharmacy_standard_items?.length > 0)
        newPharmacyStandards.push(
          ...surgery?.pharmacy_standard?.pharmacy_standard_items
        );
      if (surgery?.cssd_kit_list?.length > 0)
        newCssdKits.push(...surgery?.cssd_kit_list);
      if (surgery?.booking_notifications?.length) {
        surgery?.booking_notifications?.forEach((notification) =>
          confirmationLogs.add(notification)
        );
      }
    }

    if (pac === RADIO_CONSTANTS.YES) {
      confirmationLogs.add("PAC");
    } else if (pac === RADIO_CONSTANTS.NO) {
      confirmationLogs.delete("PAC");
    }

    if (bloodBankAssist === RADIO_CONSTANTS.YES) {
      confirmationLogs.add("Blood_Bank");
    } else if (bloodBankAssist === RADIO_CONSTANTS.NO) {
      confirmationLogs.delete("Blood_Bank");
    }

    const bookingNotifications = Array.from(confirmationLogs);

    const body = {
      surgery_number: "",
      surgery_start_time: startTime,
      surgery_end_time: endTime - bufferTime,
      surgery_type_ids: surgeryList?.map((surgery) => surgery?.id),
      cssd_kit_list: newCssdKits?.map((kit) => ({
        kit_name: kit?.kit_name,
        quantity: kit?.quantity,
      })),
      pharmacy_standard_list: newPharmacyStandards?.map((item) => ({
        name: item?.name,
        quantity: item?.quantity,
      })),
      buffer_time_required: bufferTime,
      patient_mrn: mrn?.trim(),
      patient_name: patientName?.trim(),
      notes: patientNotes?.trim(),
      pharmacy_notes: surgeryList[0]?.pharmacy_notes?.trim(),
      bed_number: "",
      requested_department: speciality?.id,
      requested_ot: otDetails?.id,
      pharmacy_special_request: specialPharmacyList?.map((item) => ({
        ...item,
        quantity: +item?.quantity,
      })),
      equipment_request: equipmentRequest,
      crew_data_details: {
        ["chief_surgeon"]: [chiefSurgeon?.id],
      },
      crew_details: newCrewDetails,
      major_surgery_type_id: surgeryList[0]?.id,
      booking_mode: bookingMode
        ? BOOKING_MODE.UN_PLANNED
        : BOOKING_MODE.PLANNED,
      booking_type: otDetails?.available_mode,
      age,
      uhid,
      type_of_anesthesia:
        anaesthesiaTypes?.length > 0
          ? anaesthesiaTypes?.map((item) => ({
              id: item?.id,
              name: item?.name,
              notes: item?.notes,
            }))
          : [],
      swc: ssi?.id ? ssi : null,
      pre_existing_infections: pei?.id ? pei : null,
      booking_notifications: bookingNotifications,
      surgery_classification: surgeryClassification,
      surgery_type: checkIsMajor,
    };

    toggleRequest(true);

    dispatch(
      createBookingRequest(units[0]?.id, body, () => toggleRequest(false))
    );
  };

  return (
    <div className={styles["quick-request-details"]}>
      {listPharmacy && (
        <Modal
          isOpen={listPharmacy}
          onCancel={() => {
            toggleListPharmacy(false);
          }}
          title={WORDINGS.PHARMACY_SUPPLIES}
          closeButton
        >
          <div className={styles["quick-request-details__pharmacy-modal"]}>
            <div className={styles["quick-request-details__pharmacy-list"]}>
              <div>{GLOBAL_CONSTANTS.VENDOR}</div>
              <div>{GLOBAL_CONSTANTS.ITEM}</div>
              <div>{GLOBAL_CONSTANTS.QUANTITY}</div>
            </div>
            {specialPharmacyList.length > 0 ? (
              Object.values(specialPharmacyList)?.map((item, index) => (
                <div
                  className={styles["quick-request-details__pharmacy-list"]}
                  key={index}
                >
                  <div>{item?.vendor}</div>
                  <div>{item?.item}</div>
                  <div>{item?.quantity}</div>
                </div>
              ))
            ) : (
              <div className={styles["quick-request-details__no-item"]}>
                {WORDINGS.NO_PHARMACY_SUPPLIES_FOUND}
              </div>
            )}
          </div>
        </Modal>
      )}
      {listEquipments && (
        <Modal
          isOpen={listEquipments}
          onCancel={() => toggleListEquipments(false)}
          title={GLOBAL_CONSTANTS.EQUIPMENTS}
          closeButton
        >
          <div className={styles["quick-request-details__equipments-modal"]}>
            <div className={styles["quick-request-details__equipments-list"]}>
              <div>{GLOBAL_CONSTANTS.EQUIPMENTS}</div>
              <div>{GLOBAL_CONSTANTS.QUANTITY}</div>
            </div>
            {Object.keys(equipmentModels).length > 0 ? (
              Object.values(equipmentModels)?.map((item, index) => (
                <div
                  className={styles["quick-request-details__equipments-list"]}
                  key={index}
                >
                  <div>{item?.name}</div>
                  <div>{item?.quantity}</div>
                </div>
              ))
            ) : (
              <div className={styles["quick-request-details__no-item"]}>
                {WORDINGS.NO_EQUIPMENTS_FOUND}
              </div>
            )}
          </div>
        </Modal>
      )}
      <div className={styles["quick-request-details__title"]}>
        <h2>{WORDINGS.REQUEST_SUMMARY}</h2>
      </div>
      <div className={styles["quick-request-details__details"]}>
        <span>{moment(surgeryDate).format(DATE_TIME_FORMAT.Do_MMMM_YYYY)}</span>
        <label className={styles["quick-request-details__Ot-name"]}>
          {`${otDetails?.name} (${
            isEmergency ? WORDINGS.NON_LISTED_HOURS : WORDINGS.LISTED_HOURS
          })`}
        </label>
        <section className={styles["quick-request-details__data-list"]}>
          {/* <label>{WORDINGS.PATIENT_INFO}</label> */}
          <div
            className={styles["quick-request-details__data-list__items-grid"]}
          >
            <img
              src={userIcon}
              alt="icon"
              className={
                styles["quick-request-details__data-list__items-grid-item"]
              }
            />
            {patientName && (
              <div
                className={joinClass(
                  styles["quick-request-details__data-list__items-grid-item"],
                  styles["quick-request-details__data-list__items-patient-name"]
                )}
              >
                {`${patientName}${age && ` (${age})`}`}
              </div>
            )}
            <div
              className={
                styles["quick-request-details__data-list__items-grid-item"]
              }
            >
              {mrn && `${GLOBAL_CONSTANTS.MR_NO}: ${mrn}`}
            </div>
            <div
              className={
                styles["quick-request-details__data-list__items-grid-item"]
              }
            >
              {uhid && `${GLOBAL_CONSTANTS.UHID}: ${uhid}`}
            </div>
            <div
              className={joinClass(
                styles["quick-request-details__data-list__items-grid-item"],
                styles["quick-request-details__data-list__items--labels"]
              )}
            >
              {ssi && <div>{ssi?.name}</div>}
              {pac === RADIO_CONSTANTS.YES && (
                <div>{WORDINGS.PAC_REQUIRED}</div>
              )}
            </div>
            {bloodBankAssist === RADIO_CONSTANTS.YES && (
              <div
                className={
                  styles["quick-request-details__data-list__items-grid-item"]
                }
              >
                <TbDropletPlus size={18} color="#b51616" />
                {WORDINGS.BLOOD_BANK_REQUIRED}
              </div>
            )}
            {anaesthesiaTypes?.length > 0 && (
              <div
                className={joinClass(
                  styles["quick-request-details__data-list__items-grid-item"],
                  styles["quick-request-details__data-list__items--list-labels"]
                )}
              >
                <li>
                  {`${WORDINGS.ANAESTHESIA}: `}
                  {anaesthesiaTypes?.length === 1
                    ? anaesthesiaTypes[0]?.name
                    : anaesthesiaTypes?.map((item, index) =>
                        index === anaesthesiaTypes.length - 1
                          ? item?.name
                          : `${item?.name}, `
                      )}
                </li>
              </div>
            )}
            {pei?.name && (
              <div
                className={joinClass(
                  styles["quick-request-details__data-list__items-grid-item"],
                  styles["quick-request-details__data-list__items--list-labels"]
                )}
              >
                <li>
                  {`${WORDINGS.PRE_EXISTING_INFECTIONS}: `}
                  {pei?.name === "Others" ? pei?.notes : pei?.name}
                </li>
              </div>
            )}
          </div>
        </section>
        <section className={styles["quick-request-details__data-list"]}>
          <label>{WORDINGS.SURGERY_DETAILS}</label>
          <div className={styles["quick-request-details__data-list__items"]}>
            <div className={styles["quick-request-details__bookingmode"]}>
              <CalendarIcon size={20} color="#0E376E" />
              {`${
                bookingMode ? WORDINGS.UN_PLANNED : WORDINGS.PLANNED
              }, ${removeUnderScore(surgeryClassification)},${removeUnderScore(
                checkIsMajor
              )} Surgery`}
            </div>
            <div>
              <FaHospital size={20} color="#0E376E" />
              {speciality?.name}
            </div>
            <div>
              <div>
                <RiScissorsCutFill size={20} color="#0E376E" />
              </div>
              {`${surgeryList?.map((surgery) => surgery?.name).join(", ")}`}
            </div>
            <div>
              <FaStethoscope size={20} color="#0E376E" />
              {chiefSurgeon?.name}
            </div>
          </div>
        </section>
        <section className={styles["quick-request-details__data-list"]}>
          <label>{WORDINGS.TIME_SLOT}</label>
          <div className={styles["quick-request-details__data-list__items"]}>
            <div>
              <FaClock size={20} color="#0E376E" />
              {`${moment(startTime).format(
                DATE_TIME_FORMAT.hh_mm_A
              )} - ${moment(endTime).format(DATE_TIME_FORMAT.hh_mm_A)}`}
            </div>
            <div>{`${GLOBAL_CONSTANTS.SURGERY_DURATION}: ${
              (endTime - startTime - bufferTime) / 60000
            } ${GLOBAL_CONSTANTS.MINS}`}</div>
            <div>{`${GLOBAL_CONSTANTS.CLEANING_DURATION}: ${
              bufferTime / 60000
            } ${GLOBAL_CONSTANTS.MINS}`}</div>
          </div>
        </section>
        {patientNotes && (
          <section className={styles["quick-request-details__data-list"]}>
            <label>{WORDINGS.NOTES}</label>
            <div className={styles["quick-request-details__data-list__items"]}>
              <div>{patientNotes}</div>
            </div>
          </section>
        )}
        <div className={styles["quick-request-details__data-list__buttons"]}>
          <NewButton
            popupButton
            onClick={() => toggleListEquipments(true)}
            disabled={!checkObjectNotEmpty(equipmentModels)}
          >
            <label
              className={
                styles["quick-request-details__data-list__button__label"]
              }
            >
              <span>{`${GLOBAL_CONSTANTS.EQUIPMENTS} (${
                Object.keys(equipmentModels)?.length
              })`}</span>
            </label>
          </NewButton>
          <NewButton
            popupButton
            className={styles["quick-request-details__data-list__button"]}
            onClick={() => toggleListPharmacy(true)}
            disabled={specialPharmacyList?.length === 0}
          >
            <label
              className={
                styles["quick-request-details__data-list__button__label"]
              }
            >
              {`${WORDINGS.PHARMACY_SUPPLIES} (${specialPharmacyList?.length})`}
            </label>
          </NewButton>
        </div>
      </div>
      <NewButton largeButton onClick={handleRequest}>
        {BUTTON_TYPES.REQUEST}
      </NewButton>
      {isRequest && <NewLoader />}
    </div>
  );
};

export default QuickRequestDetails;
