import React from "react";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";
import styles from "./index.module.css";

const Pagination = ({ totalCount, page, rowsPerPage, setPage, listCount }) => {
  return (
    <div className={styles.pagination}>
      <div className={styles["pagination__button"]}>
        {page > 1 && (
          <HiOutlineChevronLeft
            color="#0E376E"
            size={25}
            onClick={() => setPage(page - 1)}
          />
          // {/* <div>{"Previous"}</div> */}
        )}
      </div>
      <div className={styles["pagination__rows-per-page"]}>
        <div>{`${(page === 1 && page) || rowsPerPage * (page - 1) + 1} - ${
          (rowsPerPage * page > totalCount && totalCount) || listCount * page
        } of ${totalCount}`}</div>
      </div>
      <div className={styles["pagination__button"]}>
        {rowsPerPage * page < totalCount && (
          // {/* <div>{"Next"}</div> */}
          <HiOutlineChevronRight
            color="#0E376E"
            size={25}
            onClick={() => setPage(page + 1)}
          />
        )}
      </div>
    </div>
  );
};

export default Pagination;
