import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getRequestDetails,
  resetRequestDetails,
} from "../../BookingOverview/actions/bookingActions";
import Loader from "../../../components/Loader";
import Header from "../Header";
import RequestTab from "./RequestTab";
import NewButton from "../../../components/NewButton";
import Confirmation from "./Confirmation";
import Crew from "./Crew";
import { PAGE_DATA, TAB_TYPES, WORDINGS } from "../../../helpers/constants";
import { getItemFromLocalStorage, tabFilter } from "../../../helpers/utils";
import styles from "./index.module.css";

const RequestDetails = (props) => {
  const dispatch = useDispatch();
  const { units } = getItemFromLocalStorage("user") || {};
  const localTab = getItemFromLocalStorage(PAGE_DATA.TAB) || "requestDetails";

  const requestId = props.match.params.requestId;
  const [loader, setLoader] = useState(true),
    [activeTab, setActiveTab] = useState(localTab);

  useEffect(() => {
    tabFilter(activeTab);
    if (requestId) {
      setLoader(true);
      dispatch(
        getRequestDetails(units[0]?.id, requestId, () => setLoader(false))
      );
    }
    return () => dispatch(resetRequestDetails());
  }, [activeTab]);

  const { requestDetails: requestSurgeryDetails } = useSelector(
    (state) => state.bookingReducer
  );

  const tabs = [
    {
      id: TAB_TYPES.REQUEST_DETAILS,
      name: WORDINGS.REQUEST_DETAILS,
    },
    {
      id: TAB_TYPES.CONFIRMATIONS,
      name: WORDINGS.CONFIRMATIONS,
    },
    {
      id: TAB_TYPES.CREW,
      name: WORDINGS.CREW,
    },
  ];

  return (
    <div className={styles["request-details"]}>
      <Header details />
      <div className={styles["request-details__tab-container"]}>
        {tabs.map((item, index) =>
          activeTab === item?.id ? (
            <NewButton key={index} smallTabButton smallTabButtonActive>
              {item?.name}
            </NewButton>
          ) : (
            <NewButton
              key={index}
              smallTabButton
              onClick={() => setActiveTab(item.id)}
            >
              {item?.name}
            </NewButton>
          )
        )}
      </div>
      {loader ? (
        <div className={styles["request_loader"]}>
          <Loader />
        </div>
      ) : (
        <div>
          {activeTab === TAB_TYPES.REQUEST_DETAILS && (
            <RequestTab requestSurgeryDetails={requestSurgeryDetails} />
          )}
          {activeTab === TAB_TYPES.CONFIRMATIONS && (
            <Confirmation requestSurgeryDetails={requestSurgeryDetails} />
          )}
          {activeTab === TAB_TYPES.CREW && (
            <Crew requestSurgeryDetails={requestSurgeryDetails} />
          )}
        </div>
      )}
    </div>
  );
};

export default RequestDetails;
